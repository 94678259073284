import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import GlobalStyle from '../styles/GlobalStyle';
import { Wrapper } from './ui';
import SitewideNotice from './SitewideNotice';
import Header from './Header';
import LogosStrip from './LogosStrip';
import Footer from './Footer';
import CookieNotice from './CookieNotice';

const Layout = ({ isNewsSingle, children }) => {
  const {
    datoCmsSite: { faviconMetaTags },
    datoCmsLogosStripSitewide,
  } = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
      datoCmsLogosStripSitewide {
        logos {
          gatsbyImageData(height: 50)
          alt
        }
      }
    }
  `);

  const [isOverlayActive, setOverlayActive] = useState(false);

  return (
    <>
      <HelmetDatoCms favicon={faviconMetaTags}>
        <html lang="en-GB" />
      </HelmetDatoCms>
      <GlobalStyle isOverlayActive={isOverlayActive} />
      <SitewideNotice />
      <Wrapper>
        <Header
          isNewsSingle={isNewsSingle}
          setOverlayActive={setOverlayActive}
        />
        {children}
        <LogosStrip logos={datoCmsLogosStripSitewide.logos} />
        <Footer />
        <CookieNotice />
      </Wrapper>
    </>
  );
};

export default Layout;
