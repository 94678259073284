import React from 'react';
import styled from 'styled-components';
import { Svg } from './Svg';
import arrowIcon from '../../images/right-arrow.inline.svg';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  fontWeights,
} from '../../styles';

const StyledBackToTop = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 105px;
  width: 105px;
  color: ${brandColours.primary};
  ${fontSize(14)};
  font-weight: ${fontWeights.semibold};
  text-align: center;
  text-transform: uppercase;
  line-height: 1.8rem;
  background: ${standardColours.white};

  ${minBreakpointQuery.xlarge`
    top: auto;
    right: 0;
    bottom: 15px;
  `}
`;

const StyledIcon = styled(Svg)`
  display: flex;
  margin: 0 auto 10px;
  height: 23px;
  width: 23px;
  fill: ${brandColours.primary};
  transform: rotate(-90deg);
`;

export const BackToTop = ({ ...props }) => {
  return (
    <StyledBackToTop
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      {...props}
    >
      <StyledIcon image={arrowIcon} />
      Back to top
    </StyledBackToTop>
  );
};
