import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  headingStyles,
  brandColours,
  fontWeights,
  fontSize,
  zIndexLayers,
} from '../styles';
import { Container, ArrowButton } from './ui';
import { slugify } from '../utils';

const StyledCookieNotice = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px 0;
  width: 100%;
  background-color: ${brandColours.secondary};
  z-index: ${zIndexLayers.fifth};

  ${minBreakpointQuery.small`
    padding-top: 45px;
    padding-bottom: 45px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 60px;
    padding-bottom: 60px;
  `}
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${maxBreakpointQuery.medium`
    flex-direction: column;
  `}
`;

const StyledContent = styled.div`
  ${maxBreakpointQuery.medium`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.medium`
    margin-right: 20px;
    max-width: 700px;
  `}
`;

const StyledHeading = styled.h3`
  ${headingStyles()};
  margin-bottom: 15px;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.regular};
`;

const StyledText = styled.p`
  line-height: 1.5;

  ${minBreakpointQuery.small`
    ${fontSize(17)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(18)};
  `}
`;

const StyledButton = styled(ArrowButton)`
  color: ${brandColours.primary};
  border-color: ${brandColours.primary};

  svg {
    fill: ${brandColours.primary};
  }
`;

const CookieNotice = () => {
  const {
    datoCmsSite: { name },
  } = useStaticQuery(graphql`
    query CookieNoticeQuery {
      datoCmsSite {
        name
      }
    }
  `);

  const localStorageKey = `${slugify(name)}:accept-cookie-notice`;
  const [acceptedCookie, setAcceptedCookie] = useState();

  useEffect(() => {
    setAcceptedCookie(localStorage.getItem(localStorageKey) || false);
  }, [localStorageKey]);

  const acceptCookieNotice = () => {
    setAcceptedCookie(true);
    localStorage.setItem(localStorageKey, true);
  };

  return acceptedCookie === false ? (
    <StyledCookieNotice>
      <Container>
        <StyledInner>
          <StyledContent>
            <StyledHeading>Cookie Policy</StyledHeading>
            <StyledText>
              Our website uses cookies to enhance your experience. By clicking
              accept you agree to our Cookie Policy.
            </StyledText>
          </StyledContent>
          <StyledButton onClick={() => acceptCookieNotice()}>
            Yes, I accept
          </StyledButton>
        </StyledInner>
      </Container>
    </StyledCookieNotice>
  ) : (
    ''
  );
};

export default CookieNotice;
