import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, standardTransition } from '../../styles';

const StyledInViewAnimation = styled.div`
  opacity: 0;
  transform: translateY(40px);
  transition: ${standardTransition('opacity', '1s', 'ease')},
    ${standardTransition('transform', '1s', 'ease')};

  ${minBreakpointQuery.small`
    transform: translateY(60px);
  `}

  ${minBreakpointQuery.large`
    transform: translateY(80px);
  `}

  ${({ inView }) => {
    if (inView) {
      return css`
        opacity: 1;
        transform: translateY(0);

        ${minBreakpointQuery.small`
          transform: translateY(0);
        `}

        ${minBreakpointQuery.large`
          transform: translateY(0);
        `}
      `;
    }
  }}
`;

export const InViewAnimation = ({ children, ...props }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <StyledInViewAnimation ref={ref} inView={inView} {...props}>
      {children}
    </StyledInViewAnimation>
  );
};
