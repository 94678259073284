import { css } from 'styled-components';

export const absoluteCentering = () => {
  return css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  `;
};
