import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
} from '../../styles';

export const StyledHtmlContent = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 18px;
    line-height: 1.5;

    ${minBreakpointQuery.small`
      margin-bottom: 22px;
    `}

    ${minBreakpointQuery.large`
      margin-bottom: 26px;
    `}
  }

  h2 {
    color: ${brandColours.primary};
    ${fontSize(28)};

    ${minBreakpointQuery.small`
      ${fontSize(31)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(34)};
    `}
  }

  h3 {
    ${fontSize(24)};

    ${minBreakpointQuery.small`
      ${fontSize(26)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(28)};
    `}
  }

  h4 {
    ${fontSize(20)};

    ${minBreakpointQuery.small`
      ${fontSize(22)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(24)};
    `}
  }

  h5 {
    ${fontSize(18)};

    ${minBreakpointQuery.small`
      ${fontSize(20)};
    `}
  }

  h6 {
    ${fontSize(17)};

    ${minBreakpointQuery.small`
      ${fontSize(18)};
    `}
  }

  p {
    margin-bottom: 20px;
    ${fontSize(15)};
    line-height: 1.7;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
      ${fontSize(16)};
    `}

    ${minBreakpointQuery.large`
      margin-bottom: 28px;
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${brandColours.primary};

    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    margin-bottom: 20px;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}

    ${minBreakpointQuery.large`
      margin-bottom: 28px;
    `}

    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 10px;
      ${fontSize(15)};
      line-height: 1.7;

      ${minBreakpointQuery.small`
        margin-bottom: 12px;
        ${fontSize(16)};
      `}

      ${minBreakpointQuery.large`
        margin-bottom: 14px;
      `}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        display: inline-block;
        height: 8px;
        width: 8px;
        background-color: ${brandColours.primary};
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      margin-left: 16px;
    }
  }

  blockquote {
    margin: 0 0 20px 0;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}

    ${minBreakpointQuery.large`
      margin-bottom: 28px;
    `}

    &:last-child {
      margin-bottom: 0;
    }

    p {
      color: ${brandColours.primary};
      font-weight: ${fontWeights.bold};
      ${fontSize(16)};
      font-style: italic;

      ${minBreakpointQuery.small`
        ${fontSize(18)};
      `}

      ${minBreakpointQuery.large`
        ${fontSize(20)};
      `}
    
      &:before {
        content: '“';
      }

      &:after {
        content: '”';
      }
    }
  }
`;

export const HtmlContent = ({ html, ...props }) => (
  <StyledHtmlContent dangerouslySetInnerHTML={{ __html: html }} {...props} />
);
