import { createGlobalStyle, css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  zIndexLayers,
} from '../styles';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    color: ${standardColours.black};
    font-family: ${brandFonts.primary};
    font-weight: ${fontWeights.regular};
    ${fontSize(16)};
    line-height: 1.3;

    ${({ isOverlayActive }) => {
      if (isOverlayActive) {
        return css`
          &:after {
            ${minBreakpointQuery.mlarge`
              content: '';
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: ${zIndexLayers.second};
              background-color: ${standardColours.transparentBlack(0.3)};
            `}
          }
        `;
      }
    }}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  img {
    height: auto;
    width: auto;
    vertical-align: middle;
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
  }

  button,
  input,
  textarea {
    color: ${standardColours.black};
    font-family: ${brandFonts.primary};
    font-weight: ${fontWeights.regular};
    ${fontSize(16)};
    line-height: 1.3;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  input {
    ::-webkit-input-placeholder {
      color: ${standardColours.black};
    }
    ::-moz-placeholder {
      color: ${standardColours.black};
    }
    :-ms-input-placeholder {
      color: ${standardColours.black};
    }
    :-moz-placeholder {
      color: ${standardColours.black};
    }
  }
`;

export default GlobalStyle;
