import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  zIndexLayers,
  visuallyHidden,
} from '../styles';
import { Container, Svg } from './ui';
import Logo from './Logo';
import Navigation from './Navigation';
import phoneIcon from '../images/phone-icon.inline.svg';

const StyledHeader = styled.header`
  z-index: ${zIndexLayers.third};

  ${({ isNewsSingle }) => {
    if (isNewsSingle) {
      return css`
        ${maxBreakpointQuery.mlarge`
          background-color: ${brandColours.primary};
        `}

        ${minBreakpointQuery.mlarge`
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
        `}
      `;
    } else {
      return css`
        background-color: ${brandColours.primary};

        ${minBreakpointQuery.mlarge`
          position: relative;
        `}
      `;
    }
  }}
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${maxBreakpointQuery.mlarge`
    align-items: center;
  `}
`;

const StyledLogo = styled.div`
  ${maxBreakpointQuery.mlarge`
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
  `}

  ${minBreakpointQuery.mlarge`
    padding-bottom: 10px;
  `}
`;

const StyledPhone = styled.a`
  display: flex;
  align-items: center;

  ${maxBreakpointQuery.mlarge`
    margin-right: 10px;
    padding: 12px;
    border: 1px solid ${standardColours.white};
    border-radius: 50%;
  `}

  ${minBreakpointQuery.mlarge`
    order: 3;
  `}
`;

const StyledPhoneIcon = styled(Svg)`
  height: 16px;
  width: 16px;
  fill: ${standardColours.white};
`;

const StyledPhoneText = styled.span`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.mlarge`
    margin-left: 4px;
    color: ${standardColours.white};
    font-weight: ${fontWeights.bold};
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.large`
    margin-left: 6px;
    ${fontSize(15)};
  `}

  ${minBreakpointQuery.xlarge`
    margin-left: 8px;
    ${fontSize(16)};
  `}
`;

const StyledNavigation = styled(Navigation)`
  ${minBreakpointQuery.mlarge`
    display: flex;
  `}
`;

const Header = ({ isNewsSingle, setOverlayActive }) => {
  const {
    datoCmsContactInformation: { phoneNumber },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      datoCmsContactInformation {
        phoneNumber
      }
    }
  `);

  const [displayNav, setDisplayNav] = useState(false);

  return (
    <StyledHeader isNewsSingle={isNewsSingle} displayNav={displayNav}>
      <Container wide={true}>
        <StyledInner>
          <StyledLogo>
            <Logo />
          </StyledLogo>
          <StyledPhone href={`tel:${phoneNumber}`}>
            <StyledPhoneIcon image={phoneIcon} />
            <StyledPhoneText>{phoneNumber}</StyledPhoneText>
          </StyledPhone>
          <StyledNavigation
            displayNav={displayNav}
            setDisplayNav={setDisplayNav}
            setOverlayActive={setOverlayActive}
          />
        </StyledInner>
      </Container>
    </StyledHeader>
  );
};

export default Header;
