import React from 'react';
import styled, { css } from 'styled-components';
import {
  standardColours,
  fontWeights,
  minBreakpointQuery,
  standardTransition,
} from '../../styles';
import { Link } from './Link';
import { Svg } from './Svg';
import arrowIcon from '../../images/right-arrow.inline.svg';

const commonButtonStyles = () => {
  return css`
    display: inline-flex;
    justify-content: space-between;
    padding: 25px 0;
    width: 100%;
    color: ${standardColours.black};
    font-weight: ${fontWeights.semibold};
    letter-spacing: 1px;
    line-height: 2rem;
    text-transform: uppercase;
    border-top: 1px solid ${standardColours.black};
    border-bottom: 1px solid ${standardColours.black};
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.tsmall`
      max-width: 320px;
    `}

    &:hover {
      ${StyledIcon} {
        transform: translateX(0);
      }
    }
  `;
};

// moved up for hover
const StyledIcon = styled(Svg)`
  margin-left: 10px;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  fill: ${standardColours.black};
  transform: translateX(-4px);
  transition: ${standardTransition('transform')};
`;

const StyledIconButton = styled.button`
  ${commonButtonStyles()};
  text-align: left;
`;

const StyledIconButtonLink = styled(Link)`
  ${commonButtonStyles()};
`;

export const ArrowButton = ({ children, ...props }) => {
  const ArrowButtonComponent = props.to
    ? StyledIconButtonLink
    : StyledIconButton;

  return (
    <ArrowButtonComponent {...props}>
      {children}
      <StyledIcon image={arrowIcon} />
    </ArrowButtonComponent>
  );
};
