import { css } from 'styled-components';
import { fontSize } from '.';
import { minBreakpointQuery } from '../../styles';
import { brandColours, standardColours, fontWeights } from '../variables';

export const inputStyles = () => {
  return css`
    margin-top: 10px;
    padding: 10px 15px;
    height: 50px;
    width: 100%;
    background-color: ${brandColours.secondary};
    border: none;
    color: ${brandColours.primary};
    font-weight: ${fontWeights.bold};

    ${minBreakpointQuery.small`
      height: 55px;
    `}

    ${minBreakpointQuery.large`
      height: 60px;
    `}
  `;
};

export const textAreaStyles = () => {
  return css`
    margin-top: 10px;
    padding: 20px 15px;
    height: 100px;
    min-height: 50px;
    width: 100%;
    background-color: ${brandColours.secondary};
    border: none;
    resize: vertical;
    color: ${brandColours.primary};
    font-weight: ${fontWeights.bold};

    ${minBreakpointQuery.small`
      height: 150px;
      min-height: 100px;
    `}

    ${minBreakpointQuery.large`
      height: 200px;
      min-height: 150px;
    `}
  `;
};

export const formButtonStyles = () => {
  return css`
    margin-top: 10px;
    border-radius: 0px;
    color: ${standardColours.white};
    background-color: ${brandColours.primary};
    ${fontSize(16)}
    text-align: left;
    padding: 25px 30px;
    max-width: 100%;
    text-transform: none;
    border: none;

    svg {
      fill: ${standardColours.white};
    }
  `;
};
