import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
  standardTransition,
} from '../styles';
import { Video, Container, Link, Svg } from './ui';
import rmLettersBg from '../images/rm-letters-bg.svg';
import arrowIcon from '../images/right-arrow.inline.svg';

const StyledBanner = styled.section`
  position: relative;
  background: url('${rmLettersBg}') no-repeat 0 100% / contain
    ${brandColours.primary};

  ${maxBreakpointQuery.large`
    display: flex;
    flex-direction: column;
  `}

  ${({ isContact }) => {
    if (!isContact) {
      return css`
        &:before {
          content: '';
          position: absolute;
          bottom: 40px;
          left: 15px;
          height: 66px;
          width: 2px;
          background: linear-gradient(
            to bottom,
            ${standardColours.white} 50%,
            ${standardColours.transparent} 50%
          );
          background-size: 100% 200%;
          animation: scrolldown 2.2s forwards infinite;

          ${minBreakpointQuery.small`
            bottom: 50px;
          `}

          ${minBreakpointQuery.medium`
            bottom: 60px;
          `}

          ${minBreakpointQuery.large`
            content: none;
          `}
        }

        @keyframes scrolldown {
          0% {
            background-position: 0 100%;
          }

          75% {
            background-position: 0 0;
          }

          100% {
            background-position: 0 -100%;
          }
        }
      `;
    }
  }}

  ${({ isNewsSingle }) => {
    if (isNewsSingle) {
      return css`
        padding-top: 86px;

        ${minBreakpointQuery.small`
          padding-top: 100px;
        `}

        ${minBreakpointQuery.mlarge`
          padding-top: 80px;
        `}
      `;
    }
  }}

  ${({ center }) => {
    if (center) {
      return css`
        text-align: center;
      `;
    }
  }}
`;

const StyledImage = styled(GatsbyImage)`
  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        order: 2;
        opacity: 0.5;

        ${minBreakpointQuery.smedium`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 65%;
          max-width: 1250px;
        `}
      `;
    } else {
      return css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.2;
        filter: grayscale(80%);
      `;
    }
  }}
`;

const StyledVideo = styled(Video)`
  order: 2;
  opacity: 0.5;

  ${minBreakpointQuery.smedium`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 65%;
    max-width: 1250px;
  `}

  > div {
    height: 100%;

    > video {
      height: 100%;
      object-fit: cover;
    }
  }
`;

const StyledOuter = styled.div`
  position: relative;

  ${({ isContact }) => {
    if (!isContact) {
      return css`
        &:before {
          ${minBreakpointQuery.large`
            content: '';
            position: absolute;
            bottom: 70px;
            left: 0;
            height: 66px;
            width: 2px;
            background: linear-gradient(
              to bottom,
              ${standardColours.white} 50%,
              ${standardColours.transparent} 50%
            );
            background-size: 100% 200%;
            animation: scrolldown 2.2s forwards
              infinite;
          `}
        }
      `;
    }
  }}
`;

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`;

const StyledInner = styled.div`
  color: ${standardColours.white};

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        padding: 40px 0;

        ${minBreakpointQuery.smedium`
          padding-top: 100px;
          padding-bottom: 100px;
        `}
      `;
    } else {
      return css`
        padding: 60px 0 80px;

        ${minBreakpointQuery.small`
          padding-top: 80px;
          padding-bottom: 100px;
        `}

        ${minBreakpointQuery.medium`
          padding-top: 100px;
        `}
      `;
    }
  }}

  ${minBreakpointQuery.large`
    padding-top: 120px;
    padding-bottom: 200px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-top: 140px;
    padding-bottom: 220px;
  `}
`;

const StyledContent = styled.div`
  max-width: ${({ center }) => (center ? '100%' : '560px')};

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${minBreakpointQuery.smedium`
          margin-left: auto;
          max-width: 680px;
        `}
      `;
    }
  }}
`;

const StyledOverline = styled.p`
  margin-bottom: 8px;
  font-weight: ${fontWeights.medium};
  ${fontSize(12)};
  text-transform: uppercase;
  letter-spacing: 2.5px;

  ${minBreakpointQuery.small`
    margin-bottom: 10px;
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 12px;
    ${fontSize(16)};
  `}
`;

const StyledHeading = styled.h1`
  ${fluidFontSize(
    '30px',
    '65px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  line-height: 1.15;
`;

const StyledText = styled.p`
  margin-top: 18px;
  ${fontSize(22)};

  ${minBreakpointQuery.small`
    margin-top: 22px;
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 26px;
    ${fontSize(30)};
  `}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 22px;
  font-weight: ${fontWeights.medium};
  ${fontSize(18)};

  ${minBreakpointQuery.small`
    margin-top: 24px;
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 26px;
    ${fontSize(22)};
  `}
`;

const StyledLinkArrow = styled(Svg)`
  margin-right: 10px;
  padding: 8px;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  fill: ${brandColours.primary};
  background-color: ${brandColours.secondary};
  border-radius: 50%;

  ${minBreakpointQuery.small`
    margin-right: 12px;
    padding: 9px;
    height: 28px;
    width: 28px;
    min-height: 28px;
    min-width: 28px;
  `}

  ${minBreakpointQuery.large`
    margin-right: 14px;
    padding: 10px;
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
  `}
`;

// moved up for hover
const StyledCtaArrow = styled(Svg)`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  fill: ${standardColours.black};
  transition: ${standardTransition('transform')};

  ${minBreakpointQuery.small`
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
  `}

  ${minBreakpointQuery.medium`
    height: 28px;
    width: 28px;
    min-height: 28px;
    min-width: 28px;
  `}

  ${minBreakpointQuery.large`
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
  `}

  ${minBreakpointQuery.xxlarge`
    height: 36px;
    width: 36px;
    min-height: 36px;
    min-width: 36px;
  `}
`;

const StyledCta = styled(Link)`
  display: flex;
  align-items: center;
  padding: 40px;
  max-width: 360px;
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '20px',
    '30px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  background-color: ${standardColours.white};

  ${maxBreakpointQuery.large`
    align-self: flex-end;
    margin-left: 60px;
  `}

  ${minBreakpointQuery.small`
    padding: 60px 50px;
    max-width: 420px;
  `}

  ${minBreakpointQuery.medium`
    padding: 70px;
    max-width: 480px;
  `}

  ${minBreakpointQuery.large`
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 540px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 80px 90px;
    max-width: 600px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding: 85px 100px;
  `}

  &:hover {
    ${StyledCtaArrow} {
      transform: translateX(15%);
    }
  }
`;

const StyledCtaText = styled.span`
  display: block;
  margin-right: 20px;
  padding: 10px 20px 10px 0;
  border-right: 1px solid ${brandColours.quinary};

  ${minBreakpointQuery.small`
    margin-right: 30px;
    padding-right: 30px;
  `}

  ${minBreakpointQuery.medium`
    margin-right: 40px;
    padding-right: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-right: 50px;
    padding-right: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-right: 60px;
    padding-right: 60px;
  `}
`;

const Banner = ({
  overline,
  heading,
  text,
  link,
  media,
  isHomepage,
  isNewsSingle,
  isContact,
  center,
}) => {
  const {
    datoCmsBanner: { ctaLink },
  } = useStaticQuery(graphql`
    query BannerQuery {
      datoCmsBanner {
        ctaLink {
          text
          page {
            ...LinkFragment
          }
        }
      }
    }
  `);

  return (
    <StyledBanner
      isNewsSingle={isNewsSingle}
      isContact={isContact}
      center={center}
    >
      {media &&
        (media.isImage ? (
          <StyledImage
            image={media.gatsbyImageData}
            alt={media.alt}
            isHomepage={isHomepage}
          />
        ) : (
          <StyledVideo data={media} controls={false} autoPlay={true} />
        ))}
      <Container wide={true}>
        <StyledOuter isContact={isContact}>
          <StyledContainer>
            <StyledInner isHomepage={isHomepage}>
              <StyledContent isHomepage={isHomepage} center={center}>
                {overline && <StyledOverline>{overline}</StyledOverline>}
                <StyledHeading>{heading}</StyledHeading>
                {text && <StyledText>{text}</StyledText>}
                {link && (
                  <StyledLink to={link.page}>
                    <StyledLinkArrow image={arrowIcon} />
                    {link.text}
                  </StyledLink>
                )}
              </StyledContent>
            </StyledInner>
          </StyledContainer>
        </StyledOuter>
      </Container>
      {ctaLink && !isHomepage && !isNewsSingle && !isContact && (
        <StyledCta to={ctaLink.page}>
          <StyledCtaText>{ctaLink.text}</StyledCtaText>
          <StyledCtaArrow image={arrowIcon} />
        </StyledCta>
      )}
    </StyledBanner>
  );
};

export default Banner;
