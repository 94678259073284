import React from 'react';
import styled from 'styled-components';
import {
    minBreakpointQuery,
    visuallyHidden,
    brandColours,
    standardColours } from '../../styles';
import { Svg } from './Svg';
import arrowIcon from '../../images/extended-arrow.inline.svg';

const StyledSliderArrows = styled.div`
  display: flex;
  position: absolute;
  ${({ alt }) => (alt ? 'right: 0' : 'left: 0')};
  ${({ alt }) => (alt ? 'bottom: 0' : 'bottom: 50px;')};
  border: 1px solid ${brandColours.secondary};

  ${({ alt }) => !alt &&
    minBreakpointQuery.smedium`
        transform: translateX(-50%);
    `
  };
`;

const StyledControlLabel = styled.span`
  ${visuallyHidden};
`;

const StyledArrow = styled.span`
  display: block;
  padding: 12px 20px;
  line-height: 1;
  background-color: ${standardColours.white};
  cursor: pointer;

  ${minBreakpointQuery.small`
    padding: 18px 30px;
  `}

  ${minBreakpointQuery.large`
    padding: 24px 40px;
  `}

  &:first-child {
    border-right: 1px solid ${brandColours.secondary};
  }
`;

const StyledIcon = styled(Svg)`
  width: 20px;
  height: 9px;
  ${({ alt }) => (alt ? 'transform: rotate(-180deg)' : '')};

  ${minBreakpointQuery.small`
    width: 30px;
    height: 13px;
  `}

  ${minBreakpointQuery.large`
    width: 40px;
    height: 17px;
  `}
`;

export const SliderArrows = ({ sliderInstance, alt }) => (
    <StyledSliderArrows alt={alt}>
        <StyledArrow
            onClick={e => e.stopPropagation() || sliderInstance?.prev()}
        >
        <StyledControlLabel>Go to the Previous Slide</StyledControlLabel>
        <StyledIcon alt={true} image={arrowIcon} />
        </StyledArrow>
        <StyledArrow
            onClick={e => e.stopPropagation() || sliderInstance?.next()}
        >
        <StyledControlLabel>Go to the Next Slide</StyledControlLabel>
        <StyledIcon image={arrowIcon} />
        </StyledArrow>
    </StyledSliderArrows>
);