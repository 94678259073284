import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  standardColours,
  brandColours,
  fluidFontSize,
  fontSize,
  fontWeights,
  sectionMargins,
} from '../styles';
import { Container, Link, Svg, BackToTop } from './ui';
import arrowIcon from '../images/right-arrow.inline.svg';

const commonFooterStyles = () => {
  return css`
    display: flex;
    flex-direction: column;

    ${minBreakpointQuery.small`
      width: 50%;
    `}

    ${minBreakpointQuery.large`
      width: 25%;
    `}
  `;
};

const commonFooterLinkStyles = () => {
  return css`
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
  `;
};

const StyledFooter = styled.footer`
  position: relative;
  display: flex;
  padding: 0 20px 30px 0;
  color: ${standardColours.white};
  background: ${brandColours.primary};

  ${minBreakpointQuery.xlarge`
    padding-right: 105px;
  
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 105px;
      background: ${standardColours.white};
    }
  `}

  ${minBreakpointQuery.xxlarge`
    padding-right: 0;
  `}
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;

  ${minBreakpointQuery.small`
    flex-direction: row;
    flex-wrap: wrap;
  `}

  ${minBreakpointQuery.mlarge`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-bottom: 80px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-bottom: 120px;
  `}

  ${minBreakpointQuery.xxxlarge`
    margin-bottom: 160px;
  `}
`;

const StyledCTA = styled(Link)`
  display: flex;
  flex-direction: column;
  ${sectionMargins()};
  max-width: 540px;
  ${fluidFontSize(
    '35px',
    '55px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  font-weight: ${fontWeights.bold};
  line-height: 5rem;

  ${minBreakpointQuery.smedium`
    flex-direction: row;
    line-height: 6.5rem;
  `}

  ${minBreakpointQuery.mlarge`
    max-width: 560px;
  `}
`;

const StyledCTAIcon = styled(Svg)`
  margin-bottom: 25px;
  height: 52px;
  width: 52px;
  min-height: 52px;
  min-width: 52px;
  fill: ${standardColours.white};

  ${minBreakpointQuery.smedium`
    position: relative;
    top: 15px;
    margin-right: 30px;
    height: 73px;
    width: 73px;
    min-height: 73px;
    min-width: 73px;
  `}
`;

const StyledNav = styled.nav`
  ${commonFooterStyles()};
  padding-bottom: 30px;
  line-height: 3.8rem;
`;

const StyledSection = styled.section`
  ${commonFooterStyles()};
`;

const StyledNavHeading = styled.h3`
  padding: 0 0 15px;
  ${fontSize(20)};
  font-weight: ${fontWeights.semibold};
  line-height: 3rem;
`;

const StyledNavLink = styled(Link)`
  ${commonFooterLinkStyles()}
`;

const StyledContactLink = styled.a`
  ${commonFooterLinkStyles()}
`;

const StyledList = styled.ul`
  padding: 5px 0;
`;

const StyledListItem = styled.li`
  padding-bottom: 30px;
  max-width: 270px;
  line-height: 2.5rem;
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 3rem;

  ${minBreakpointQuery.mlarge`
    flex-direction: row;
    justify-content: flex-start;
  `}
`;

const StyledInfoText = styled.p`
  padding: 5px 0;

  ${minBreakpointQuery.mlarge`
    padding-right: 40px;
  `}
`;

const StyledInfoLink = styled.a`
  padding: 5px 0 0;
  text-decoration: underline;
  text-decoration-thickness: 2px;

  ${minBreakpointQuery.mlarge`
    margin-left: auto;
  `}
`;

const Footer = () => {
  const {
    site: {
      globalSeo: { siteName },
    },
    footer: {
      footerCtaLink,
      aboutTitle,
      aboutLinks,
      infoTitle,
      infoLinks,
      contactTitle,
      contactLink,
      locationTitle,
      companyNumber,
      vatNumber,
    },
    contact: { phoneNumber, emailAddress, locations, linkedinProfile },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site: datoCmsSite {
        globalSeo {
          siteName
        }
      }
      footer: datoCmsFooter {
        companyNumber
        vatNumber
        aboutLinks {
          id
          text
          page {
            ...LinkFragment
          }
        }
        aboutTitle: aboutNavigationTitle
        infoTitle: informationNavigationTitle
        contactTitle
        contactLink {
          id
          text
          page {
            ...LinkFragment
          }
        }
        locationTitle
        infoLinks: informationLinks {
          id
          text
          page {
            ...LinkFragment
          }
        }
        footerCtaLink {
          text
          page {
            ...LinkFragment
          }
        }
      }
      contact: datoCmsContactInformation {
        phoneNumber
        emailAddress
        locations {
          heading
          address
        }
        linkedinProfile
      }
    }
  `);

  return (
    <StyledFooter id="footer">
      <Container>
        {footerCtaLink && (
          <StyledCTA to={footerCtaLink.page}>
            {' '}
            <StyledCTAIcon image={arrowIcon} />
            {footerCtaLink.text}
          </StyledCTA>
        )}

        <StyledInner>
          <StyledNav>
            <StyledNavHeading>{aboutTitle}</StyledNavHeading>
            {aboutLinks.map(({ id, text, page }) => (
              <StyledNavLink key={id} to={page}>
                {text}
              </StyledNavLink>
            ))}
          </StyledNav>

          <StyledNav>
            <StyledNavHeading>{infoTitle}</StyledNavHeading>
            {infoLinks.map(({ id, text, page }) => (
              <StyledNavLink key={id} to={page}>
                {text}
              </StyledNavLink>
            ))}
          </StyledNav>

          <StyledNav>
            <StyledNavHeading>{contactTitle}</StyledNavHeading>
            <StyledContactLink
              href={`tel:${phoneNumber}`}
              title="Click here to give us a call"
              target="_blank"
              rel="noopener noreferrer"
            >
              {phoneNumber}
            </StyledContactLink>
            <StyledContactLink
              href={`mailto:${emailAddress}`}
              title="Click here to email us"
              target="_blank"
              rel="noopener noreferrer"
            >
              {emailAddress}
            </StyledContactLink>
            {linkedinProfile && (
              <p>
                Find us on{' '}
                <StyledInfoLink
                  href={linkedinProfile}
                  title={`Visit the ${siteName} LinkedIn Profile`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Linked In
                </StyledInfoLink>
              </p>
            )}
            {contactLink && (
              <StyledNavLink key={contactLink.id} to={contactLink.page}>
                {contactLink.text}
              </StyledNavLink>
            )}
          </StyledNav>

          <StyledSection>
            <StyledNavHeading>{locationTitle}</StyledNavHeading>
            <StyledList>
              {locations.map(({ address }, id) => (
                <StyledListItem key={id}>{address}</StyledListItem>
              ))}
            </StyledList>
          </StyledSection>
        </StyledInner>

        <StyledInfo>
          <StyledInfoText>
            &copy; {new Date().getFullYear()} {siteName}
          </StyledInfoText>
          <StyledInfoText>Company No: {companyNumber}</StyledInfoText>
          <StyledInfoText>VAT No: {vatNumber}</StyledInfoText>
          <StyledInfoLink
            href="http://www.madebyswish.co.uk"
            title="Swish - An Independent Design Studio"
            target="_blank"
            rel="noopener noreferrer"
          >
            madebyswish®
          </StyledInfoLink>
        </StyledInfo>
      </Container>

      <BackToTop />
    </StyledFooter>
  );
};

export default Footer;
