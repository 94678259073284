import React from 'react';
import styled, { css } from 'styled-components';
import { headingStyles } from '../../styles';

const StyledHeading = styled.h2`
  ${({ alt }) => {
    return css`
      ${headingStyles(alt)};
    `;
  }}
`;

export const Heading = ({ alt, children, ...props }) => (
  <StyledHeading alt={alt} {...props}>
    {children}
  </StyledHeading>
);
