import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  zIndexLayers,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Link, Svg, Container } from './ui';
import arrowIcon from '../images/right-arrow.inline.svg';
import stripBlueVertical from '../images/strip-blue-vertical.inline.svg';

const StyledNavigation = styled.nav``;

const StyledToggle = styled.button`
  ${maxBreakpointQuery.mlarge`
    position: relative;
    margin: 20px 0;
    padding: 12px;
    background-color: ${brandColours.secondary};
    border-radius: 50%;
  `}

  ${minBreakpointQuery.mlarge`
    display: none;
  `}

  span {
    ${maxBreakpointQuery.mlarge`
      display: block;
      height: 2px;
      width: 14px;
      margin: 3px 2px;
      background-color: ${brandColours.primary};
      transition: ${standardTransition('opacity')},
        ${standardTransition('transform')};
    `}

    ${({ displayNav }) => {
      if (displayNav) {
        return css`
          ${maxBreakpointQuery.mlarge`
            opacity: 0;
          `}

          &:first-child {
            ${maxBreakpointQuery.mlarge`
              opacity: 1;
              transform: translateY(5px) rotate(45deg);
            `}
          }

          &:last-child {
            ${maxBreakpointQuery.mlarge`
              opacity: 1;
              transform: translateY(-5px) rotate(-45deg);
            `}
          }
        `;
      }
    }}
  }
`;

const StyledList = styled.ul`
  ${maxBreakpointQuery.mlarge`
    position: absolute;
    right: 0;
    left: 0;
    z-index: ${zIndexLayers.fourth};
    display: ${({ displayNav }) => (displayNav ? 'block' : 'none')};
    padding: 30px;
    height: 100%;
    background-color: ${brandColours.primary}
  `}

  ${minBreakpointQuery.mlarge`
    display: flex;
  `}
`;

// moved up for hover
const StyledLink = styled(Link)`
  position: relative;
  color: ${standardColours.white};
  font-weight: ${fontWeights.semibold};
  transition: ${standardTransition('color')};

  ${maxBreakpointQuery.mlarge`
    display: flex;
    padding: 10px 30px 10px 0;
    ${fontSize(22)};
  `}

  ${maxBreakpointQuery.smedium`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.mlarge`
    display: flex;
    align-items: center;
    height: 100%;
  `}

  ${minBreakpointQuery.xlarge`
    ${fontSize(17)};
  `}

  &:before {
    ${minBreakpointQuery.mlarge`
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      height: 18px;
      width: 2px;
      background-color: ${standardColours.white};
      transform: translateX(-50%) translateY(-100%);
      transition: ${standardTransition('transform')};
    `}

    ${minBreakpointQuery.large`
      height: 22px;
    `}
  }
`;

// moved up for hover
const StyledMegaMenu = styled.div`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.mlarge`
    position: absolute;
    left: -9999em;
    z-index: 1;
    width: 100%;
  `}
`;

const StyledItem = styled.li`
  ${maxBreakpointQuery.mlarge`
    margin-top: 10px;
  `}

  ${minBreakpointQuery.mlarge`
    display: inline-block;
    margin-left: 12px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 20px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 30px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-left: 40px;
  `}

  &:first-child {
    ${maxBreakpointQuery.mlarge`
      margin-top: 0;
    `}

    ${minBreakpointQuery.mlarge`
      margin-left: 0;
    `}
  }

  &:hover ${StyledLink}, ${StyledLink}.current-page {
    &:before {
      transform: translateX(-50%) translateY(0);
    }
  }

  &:hover {
    ${StyledMegaMenu} {
      ${minBreakpointQuery.mlarge`
        left: 0;
      `}
    }
  }
`;

const StyledArrow = styled(Svg)`
  ${maxBreakpointQuery.mlarge`
    position: absolute;
    top: 50%;
    right: 0;
    height: 14px;
    width: 14px;
    fill: ${standardColours.white};
    transform: translateY(-50%);
  `}

  ${minBreakpointQuery.mlarge`
    display: none;
  `}
`;

const StyledSubMenu = styled.div`
  ${minBreakpointQuery.mlarge`
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: ${standardColours.white};
  `}

  ${minBreakpointQuery.large`
    padding-top: 80px;
    padding-bottom: 80px;
  `}
`;

const StyledSubName = styled.p`
  ${minBreakpointQuery.mlarge`
    margin-bottom: 20px;
    color: ${brandColours.quinary};
    font-weight: ${fontWeights.semibold};
    ${fontSize(14)};
  `}
`;

const StyledSubList = styled.ul`
  ${minBreakpointQuery.mlarge`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 20px;
    max-height: 200px;
    max-width: 500px;
  `}
`;

const StyledSubItem = styled.li`
  ${minBreakpointQuery.mlarge`
    margin-top: 8px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 10px;
  `}

  &:first-child {
    ${minBreakpointQuery.mlarge`
      margin-top: 0;
    `}
  }
`;

const StyledSubLink = styled(Link)`
  ${minBreakpointQuery.mlarge`
    padding: 5px 0;
    color: ${brandColours.primary};
    font-weight: ${fontWeights.semibold};
  `}

  &:hover {
    ${minBreakpointQuery.mlarge`
      text-decoration: underline;
    `}
  }
`;

const StyledStrip = styled(Svg)`
  ${minBreakpointQuery.mlarge`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80px;
  `}
`;

const Navigation = ({
  displayNav,
  setDisplayNav,
  setOverlayActive,
  ...props
}) => {
  const {
    allDatoCmsNavigation: { nodes },
  } = useStaticQuery(graphql`
    query NavigationQuery {
      allDatoCmsNavigation(
        filter: { root: { eq: true } }
        sort: { fields: position }
      ) {
        nodes {
          id
          link {
            text
            page {
              ...LinkFragment
            }
          }
          treeChildren {
            id
            link {
              text
              page {
                ...LinkFragment
              }
            }
          }
        }
      }
    }
  `);

  return (
    <StyledNavigation {...props}>
      <StyledToggle
        aria-label="Open / Close Navigation"
        displayNav={displayNav}
        onClick={() => setDisplayNav(!displayNav)}
      >
        <span />
        <span />
        <span />
      </StyledToggle>
      <StyledList displayNav={displayNav}>
        {nodes.map(({ id, link: { text, page }, treeChildren }) => {
          const hasChildren = treeChildren.length > 0;

          return (
            <StyledItem
              key={id}
              onMouseOver={() => hasChildren && setOverlayActive(true)}
              onMouseOut={() => hasChildren && setOverlayActive(false)}
            >
              <StyledLink
                to={page}
                activeClassName="current-page"
                partiallyActive={true}
              >
                <span>{text}</span>
                <StyledArrow image={arrowIcon} />
              </StyledLink>
              {hasChildren && (
                <StyledMegaMenu>
                  <StyledSubMenu>
                    <Container>
                      <StyledSubName>{text}</StyledSubName>
                      <StyledSubList>
                        {treeChildren
                          .sort((a, b) => a.position - b.position)
                          .map(({ id, link: { text, page } }) => (
                            <StyledSubItem key={id}>
                              <StyledSubLink to={page}>{text}</StyledSubLink>
                            </StyledSubItem>
                          ))}
                      </StyledSubList>
                    </Container>
                    <StyledStrip image={stripBlueVertical} />
                  </StyledSubMenu>
                </StyledMegaMenu>
              )}
            </StyledItem>
          );
        })}
      </StyledList>
    </StyledNavigation>
  );
};

export default Navigation;
