export const standardColours = {
  black: '#000',
  transparent: 'rgba(255, 255, 255, 0)',
  transparentBlack: value => {
    return `rgba(0, 0, 0, ${value})`;
  },
  white: '#FFF',
};

export const brandColours = {
  primary: '#001D8A', // blue
  secondary: '#DBDBDB', // light grey
  tertiary: '#7E7E7E', // dark grey
  quaternary: '#001976', // dark blue
  quinary: '#979797', // grey
  senary: '#D8D8D8', // light grey
};
