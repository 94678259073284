import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontSize,
} from '../styles';
import { Container } from './ui';

const StyledSitewideNotice = styled.div`
  padding: 10px 0;
  background-color: ${brandColours.secondary};

  ${minBreakpointQuery.small`
    padding-top: 15px;
    padding-bottom: 15px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 20px;
    padding-bottom: 20px;
  `}
`;

const StyledText = styled.div`
  p {
    color: ${brandColours.primary};

    ${maxBreakpointQuery.medium`
      ${fontSize(14)};
    `}

    a {
      text-decoration: underline;
    }
  }
`;

const SitewideNotice = () => {
  const {
    datoCmsHeader: { sitewideNotice },
  } = useStaticQuery(graphql`
    query SitewideNoticeQuery {
      datoCmsHeader {
        sitewideNotice
      }
    }
  `);

  return (
    sitewideNotice && (
      <StyledSitewideNotice>
        <Container wide={true}>
          <StyledText dangerouslySetInnerHTML={{ __html: sitewideNotice }} />
        </Container>
      </StyledSitewideNotice>
    )
  );
};

export default SitewideNotice;
