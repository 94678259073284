import React from 'react';
import styled from 'styled-components';
import { brandColours, minBreakpointQuery, standardColours } from '../../styles';

const StyledSliderDots = styled.ul`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 30px;

    ${minBreakpointQuery.smedium`
        margin-top: 60px;
    `};

    ${minBreakpointQuery.medium`
        margin-top: 80px;
    `};
`;

const StyledSliderDot = styled.li`
    background-color: ${brandColours.senary};
    width: 11px;
    height: 11px;
    border-radius: 50%;
    cursor: pointer;

    ${({active}) => active && `
        background-color: ${standardColours.white};
        border: 1px solid ${brandColours.primary};
    `};
`;

export const SliderDots = ({ slides, sliderInstance }) => (
    <StyledSliderDots>
        {slides.map((_, id) => (
            <StyledSliderDot
                active={sliderInstance?.details().relativeSlide === id}
                onClick={e => e.stopPropagation() || sliderInstance?.moveToSlideRelative(id)}
            />
        ))}
    </StyledSliderDots>
);
