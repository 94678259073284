import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { sectionMargins, visuallyHidden } from '../styles';
import { Container } from './ui';

const StyledLogosStrip = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledList = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const StyledListItem = styled.li``;

const StyledImage = styled(GatsbyImage)`
  max-width: 100px;
  filter: grayscale(1);
`;

const LogosStrip = ({ heading, logos }) => (
  <StyledLogosStrip>
    <Container>
      {heading && <StyledHeading>{heading}</StyledHeading>}

      <StyledList>
        {logos.map((image, index) => (
          <StyledListItem key={index}>
            <StyledImage image={image.gatsbyImageData} alt={image.alt} />
          </StyledListItem>
        ))}
      </StyledList>
    </Container>
  </StyledLogosStrip>
);

export default LogosStrip;
