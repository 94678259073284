import { css } from 'styled-components';
import { breakpointSizes, brandColours, fluidFontSize } from '../../styles';

// Base Heading Styles
export const headingStyles = alt => {
  return css`
    color: ${brandColours.primary};
    ${fluidFontSize(
      alt ? '35px' : '30px',
      alt ? '50px' : '40px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
  `;
};
